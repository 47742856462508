<template>
  <div id="wheelOfFortune">
    <div>
      <div class="sign">
        <span class="fast-flicker">g</span>lücks<span class="flicker">r</span>ad
      </div>
    </div>
    <div>
      <div>
        <FortuneWheel
          class="wheel"
          :canvas="canvasOptions"
          :prizeId="multiplier"
          :prizes="prizes"
          @rotateStart="onCanvasRotateStart"
          @rotateEnd="onRotateEnd"
        />
      </div>
    </div>
    <div class="einsatzArea">
      <label class="einsatz">Einsatz: </label>
      <b-form-input
        type="number"
        class="einsatz borderEinsatz"
        v-model="einsatz"
      ></b-form-input>
    </div>
    <div class="neons">
      <em>Klicke auf 'GO' um zu drehen</em>
    </div>
    <b-modal
      id="winningModal"
      centered
      ok-title="Weiter"
      body-bg-variant="success"
      body-text-variant="dark"
      hide-footer
      hide-header
      ><div style="margin: 40px; font-size: 1.5rem">
        <center>
          <em
            >Glückwunsch!<br />
            Sie haben {{ wonMoney }}€ gewonnen!</em
          >
          <br />
          <br />
          Neuer Guthabenstand: {{ balance }}€
        </center>
      </div>
      <div>
        <center>
          <b-button
            style="
              margin-bottom: 20px;
              padding: 10px 20px 10px 20px;
              font-size: 1.3rem;
            "
            variant="dark"
            @click="closeModal"
          >
            Weiter
          </b-button>
        </center>
      </div>
    </b-modal>
    <b-modal
      id="loosingModal"
      centered
      ok-title="Weiter"
      body-bg-variant="info"
      body-text-variant="dark"
      hide-footer
      hide-header
      ><div style="margin: 40px; font-size: 1.5rem">
        <center>
          <em
            >Schade...<br />
            Leider haben Sie kein Geld gewonnen - vielleicht nächstes Mal!</em
          >
          <br />
          <br />
          Neuer Guthabenstand: {{ balance }}€
        </center>
      </div>
      <div>
        <center>
          <b-button
            style="
              margin-bottom: 20px;
              padding: 10px 20px 10px 20px;
              font-size: 1.3rem;
            "
            variant="dark"
            @click="closeLoosingModal"
          >
            Weiter
          </b-button>
        </center>
      </div>
    </b-modal>
    <b-modal
      id="noMoneyErrorModal"
      centered
      ok-only
      title="Error"
      body-bg-variant="danger"
      footer-bg-variant="danger"
      hide-header
    >
      {{ errorMessage }}
    </b-modal>
  </div>
</template>

<script>
import DataService from "../services/data.service";
import FortuneWheel from "vue-fortune-wheel";
import "vue-fortune-wheel/lib/vue-fortune-wheel.css";

export default {
  components: {
    FortuneWheel,
  },
  data() {
    return {
      errorMessage: "",
      einsatz: 5,
      multiplier: 69,
      success: true,
      wonMoney: 0,
      balance: 100,
      canvasOptions: {
        borderWidth: 6,
        borderColor: "#000000",
      },
      balance: 0,
      balance_after: 0,
      balance_before: 0,
      prizes: [
        {
          //Blue
          id: 69,
          name: "x0",
          value: 5,
          bgColor: "#e26258",
          color: "#ffffff",
          probability: 1,
          weight: 1,
        },
        {
          //Yellow
          id: 2,
          name: "x2",
          value: 20,
          bgColor: "#DE970B",
          color: "#000000",
          probability: 99,
          weight: 1,
        },
        {
          //Blue
          id: 3,
          name: "x3",
          value: 5,
          bgColor: "#192841",
          color: "#ffffff",
          weight: 1,
        },
        {
          //Yellow
          id: 5,
          name: "x5",
          value: 20,
          bgColor: "#800080",
          color: "#000000",
          weight: 1,
        },
        {
          //Red
          id: 10,
          name: "x10",
          value: 50,
          bgColor: "#8b0000",
          color: "#000000",
          weight: 1,
        },
        {
          //light blue
          id: 20,
          name: "x20",
          value: 20,
          bgColor: "#298286",
          color: "#000000",
          weight: 1,
        },
        {
          //green
          id: 100,
          name: "x100",
          value: 5,
          bgColor: "#728c55",
          color: "#ffffff",
          weight: 1,
        },
      ],
    };
  },
  methods: {
    onCanvasRotateStart() {
      let user = DataService.getLoggedInUser();
      DataService.playLuckywheel(user.id, this.einsatz).then((response) => {
        if (!response.success) {
          this.errorMessage = response.message;
          this.success = response.success;
          this.$bvModal.show("noMoneyErrorModal");
          return;
        }
        this.$root.$emit("navBar", response.balance_before - this.einsatz);
        this.$data.balance_after = response.balance_after;
        this.$data.balance_before = response.balance_before;
        this.$data.success = response.success;
        if (response.multiplier === 0) {
          response.multiplier = 69;
        }
        this.$data.multiplier = response.multiplier;
        const verified = true;
        this.DoServiceVerify(verified, 2000);
        return;
      });
    },

    onRotateEnd() {
      if (this.$data.success === false) {
      } else {
        this.$data.wonMoney = this.einsatz * this.$data.multiplier;
        this.$data.balance = this.$data.balance_after;
        if (this.$data.multiplier !== 69) {
          this.proceedAsWin();
        } else {
          this.proceedAsLoose();
        }
      }
      this.$root.$emit("navBar", this.$data.balance_after);
    },

    proceedAsWin() {
      this.$bvModal.show("winningModal");
    },

    proceedAsLoose() {
      this.$bvModal.show("loosingModal");
    },

    closeLoosingModal() {
      this.$bvModal.hide("loosingModal");
    },

    DoServiceVerify(verified, duration) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(verified);
        }, duration);
      });
    },
    closeModal() {
      this.$bvModal.hide("winningModal");
    },
  },
  created() {
    if (!DataService.checkLoggedIn()) {
      this.$router.push("/Login");
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Codystar:300&display=swap");

.einsatz {
  width: 80%;
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
}

.einsatzArea {
  background-color: rgb(46, 46, 46);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: block;
  margin: 0 auto;
  color: white;
  width: 25%;
  padding: 8px;
  opacity: 0.9;
  margin-bottom: 15px;
}

.neons {
  font-size: 2rem;
  text-align: center;
  font-weight: bold;
  -webkit-animation: glow 2s ease-in-out infinite alternate;
  -moz-animation: glow 2s ease-in-out infinite alternate;
  animation: glow 2s ease-in-out infinite alternate;
}

@-webkit-keyframes glow {
  from {
    color: #fff;
    text-shadow: 0 0 10px #0f6d68, 0 0 20px #0f6d68, 0 0 30px #0f6d68,
      0 0 40px #0f6d68, 0 0 50px #0f6d68, 0 0 60px #0f6d68, 0 0 70px #0f6d68,
      0 0 90px #0f6d68;
  }

  to {
    color: grey;
    text-shadow: 0 0 20px #0f6d68, 0 0 30px #0f6d68, 0 0 40px #0f6d68,
      0 0 50px #0f6d68, 0 0 60px #0f6d68, 0 0 70px #0f6d68, 0 0 80px #0f6d68,
      0 1 90px #0f6d68;
  }
}

.wheel {
  width: 25%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  display: block !important;
  margin-top: 20px;
  margin-bottom: 30px;
  cursor: pointer;
  text-shadow: 0 0 0.6rem #575757, 0 0 1.5rem #575757,
    -0.2rem 0.1rem 1rem #575757, 0.2rem 0.1rem 1rem #575757,
    0 -0.5rem 2rem #575757, 0 0.5rem 3rem #575757;
}

.sign {
  display: block;
  margin: 0 auto;
  width: fit-content;
  background-image: radial-gradient(
    ellipse 50% 35% at 50% 50%,
    #6b1839,
    transparent
  );
  letter-spacing: 2;
  font-family: "Clip";
  text-transform: uppercase;
  font-size: 3.5em;
  color: #ffe6ff;
  text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
    -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
    0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
  animation: shine 2s forwards, flicker 3s infinite;
}

@keyframes blink {
  0%,
  22%,
  36%,
  75% {
    color: #ffe6ff;
    text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
      -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
      0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
  }
  28%,
  33% {
    color: #ff65bd;
    text-shadow: none;
  }
  82%,
  97% {
    color: #ff2483;
    text-shadow: none;
  }
}

.flicker {
  animation: shine 2s forwards, blink 3s 2s infinite;
}

.fast-flicker {
  animation: shine 2s forwards, blink 10s 1s infinite;
}

@keyframes shine {
  0% {
    color: #6b1839;
    text-shadow: none;
  }
  100% {
    color: #ffe6ff;
    text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
      -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
      0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
  }
}

@keyframes flicker {
  from {
    opacity: 1;
  }

  4% {
    opacity: 0.9;
  }

  6% {
    opacity: 0.85;
  }

  8% {
    opacity: 0.95;
  }

  10% {
    opacity: 0.9;
  }

  11% {
    opacity: 0.922;
  }

  12% {
    opacity: 0.9;
  }

  14% {
    opacity: 0.95;
  }

  16% {
    opacity: 0.98;
  }

  17% {
    opacity: 0.9;
  }

  19% {
    opacity: 0.93;
  }

  20% {
    opacity: 0.99;
  }

  24% {
    opacity: 1;
  }

  26% {
    opacity: 0.94;
  }

  28% {
    opacity: 0.98;
  }

  37% {
    opacity: 0.93;
  }

  38% {
    opacity: 0.5;
  }

  39% {
    opacity: 0.96;
  }

  42% {
    opacity: 1;
  }

  44% {
    opacity: 0.97;
  }

  46% {
    opacity: 0.94;
  }

  56% {
    opacity: 0.9;
  }

  58% {
    opacity: 0.9;
  }

  60% {
    opacity: 0.99;
  }

  68% {
    opacity: 1;
  }

  70% {
    opacity: 0.9;
  }

  72% {
    opacity: 0.95;
  }

  93% {
    opacity: 0.93;
  }

  95% {
    opacity: 0.95;
  }

  97% {
    opacity: 0.93;
  }

  to {
    opacity: 1;
  }
}
</style>
